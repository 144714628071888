<template>
  <div>
    <div ref="mapContainer" :class="['tw-mt-1 map', sizeClass]"></div>
    <div v-if="legendUrls.length && showLegend" class="tw-mt-2 tw-bg-white tw-shadow-md tw-rounded tw-p-2">
      <h3 class="tw-text-lg tw-font-semibold">Legende</h3>
      <div v-for="(legend, index) in legendUrls" :key="index" class="tw-mt-1 tw-flex tw-items-center">
        <img :src="legend.url" alt="Legend" class="tw-max-w-full tw-h-auto tw-mr-2">
      </div>
    </div>
  </div>
</template>

<script>
import 'ol/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import XYZ from 'ol/source/XYZ'
import TileWMS from 'ol/source/TileWMS'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import { fromLonLat } from 'ol/proj'
import { defaults as defaultInteractions } from 'ol/interaction'
import { Style, Fill, Stroke, Circle as CircleStyle } from 'ol/style'

export default {
  name: 'EntityLayerMap',
  props: {
    location: {
      type: Object,
      required: true
    },
    region: {
      type: String,
      default: 'flanders'
    },
    minZoom: {
      type: Number,
      default: 10
    },
    maxZoom: {
      type: Number,
      default: 20
    },
    defaultZoom: {
      type: Number,
      default: 18
    },
    mapType: {
      type: String,
      default: 'default'
    },
    baseType: {
      type: String,
      default: 'osm'
    },
    showLegend: {
      type: Boolean,
      default: true
    },
    dragEnabled: {
      type: Boolean,
      default: true
    },
    keepZoomCentered: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
      map: null,
      locationSource: new VectorSource(),
      layers: {
        osm: new TileLayer({
          source: new XYZ({
            url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            crossOrigin: 'anonymous'
          })
        }),
        satellite: new TileLayer({
          source: new XYZ({
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            crossOrigin: 'anonymous'
          })
        })
      },
      overlayLayers: {
        default: {
          flanders: null,
          brussels: null
        },
        regionPlan: {
          flanders: new TileLayer({
          // Docs at: https://www.mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/ows?SERVICE=WMS&service=WMS&REQUEST=Capabilities
            source: new TileWMS({
              url: 'https://www.mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/ows?SERVICE=WMS&service=WMS',
              crossOrigin: 'anonymous',
              params: {
                LAYERS: 'lu:lu_gwp_rv_raster',
                FORMAT: 'image/png',
                TRANSPARENT: 'true',
                VERSION: '1.3.0'
              },
              serverType: 'geoserver',
              opacity: 1
            })
          }),
          brussels: null
        },
        cadaster: {
          flanders: new TileLayer({
            // Docs at: https://geo.api.vlaanderen.be/GRB/wms?service=WMS&request=getcapabilities
            source: new TileWMS({
              url: 'https://geo.api.vlaanderen.be/GRB/wms', // WMS URL
              crossOrigin: 'anonymous',
              params: {
                LAYERS: [
                  'GRB_ADP',
                  'GRB_GBG',
                  'GRB_WBN',
                  'GRB_WTZ',
                  'GRB_TRN'
                ],
                FORMAT: 'image/png',
                TRANSPARENT: 'true',
                VERSION: '1.3.0'
              },
              serverType: 'geoserver',
              opacity: 1
            }),
            zIndex: 1
          }),
          brussels: null
        },
        waterPolicyAdvice: {
          flanders: new TileLayer({
            // Docs at: https://vha.waterinfo.be/arcgis/services/advieskaart/MapServer/WMSServer?request=Capabilities&service=WMS
            source: new TileWMS({
              url: 'https://vha.waterinfo.be/arcgis/services/advieskaart/MapServer/WMSServer',
              crossOrigin: 'anonymous',
              params: {
                LAYERS: [0],
                FORMAT: 'image/png',
                TRANSPARENT: 'true',
                VERSION: '1.3.0'
              },
              opacity: 1
            })
          }),
          brussels: null
        },
        waterPolicy: {
          flanders: [
            new TileLayer({
              // Docs at: https://inspirepub.waterinfo.be/arcgis/services/informatieplicht/overstromingsgevoelige_gebieden_vanuit_de_zee/MapServer/WMSServer?request=Capabilities&service=WMS
              source: new TileWMS({
                url: 'https://inspirepub.waterinfo.be/arcgis/services/informatieplicht/overstromingsgevoelige_gebieden_vanuit_de_zee/MapServer/WMSServer',
                crossOrigin: 'anonymous',
                params: {
                  LAYERS: [0],
                  FORMAT: 'image/png',
                  TRANSPARENT: 'true',
                  VERSION: '1.3.0'
                },
                opacity: 1
              })
            }),
            new TileLayer({
              // Docs at: https://inspirepub.waterinfo.be/arcgis/services/informatieplicht/overstromingsgevoelige_gebieden_pluviaal/MapServer/WMSServer?request=Capabilities&service=WMS
              source: new TileWMS({
                url: 'https://inspirepub.waterinfo.be/arcgis/services/informatieplicht/overstromingsgevoelige_gebieden_pluviaal/MapServer/WMSServer',
                crossOrigin: 'anonymous',
                params: {
                  LAYERS: [0],
                  FORMAT: 'image/png',
                  TRANSPARENT: 'true',
                  VERSION: '1.3.0'
                },
                opacity: 1
              })
            }),
            new TileLayer({
              // Docs at: https://inspirepub.waterinfo.be/arcgis/services/informatieplicht/overstromingsgevoelige_gebieden_fluviaal/MapServer/WMSServer?request=Capabilities&service=WMS
              source: new TileWMS({
                url: 'https://inspirepub.waterinfo.be/arcgis/services/informatieplicht/overstromingsgevoelige_gebieden_fluviaal/MapServer/WMSServer',
                crossOrigin: 'anonymous',
                params: {
                  LAYERS: [0],
                  FORMAT: 'image/png',
                  TRANSPARENT: 'true',
                  VERSION: '1.3.0'
                },
                opacity: 1
              })
            })
          ],
          brussels: null
        },
        preemption: {
          flanders: new TileLayer({
            source: new TileWMS({
              // Docs at: https://geo.api.vlaanderen.be/RVVAfbak/wms?request=getCapabilities&service=WMS
              url: 'https://geo.api.vlaanderen.be/RVVAfbak/wms',
              crossOrigin: 'anonymous',
              params: {
                LAYERS: 'RVVVerzamel',
                FORMAT: 'image/png',
                TRANSPARENT: 'true',
                VERSION: '1.1.1'
              },
              serverType: 'geoserver',
              opacity: 1
            })
          }),
          brussels: null
        },
        heritage: {
          flanders: new TileLayer({
            // Docs at: https://www.mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities
            source: new TileWMS({
              url: 'https://www.mercator.vlaanderen.be/raadpleegdienstenmercatorpubliek/wms',
              crossOrigin: 'anonymous',
              params: {
                LAYERS: [
                  'ps:ps_bes_land',
                  'ps:ps_bes_arch_site',
                  'ps:ps_bes_sd_gezicht',
                  'ps:ps_bes_monument',
                  'ps:ps_bes_ovgzone',
                  'ps:ps_vast_la_rel',
                  'ps:ps_vast_le',
                  'ps:ps_vast_az',
                  'ps:ps_vast_be',
                  'am:am_erfgls',
                  'ps:ps_unesco_kern',
                  'ps:ps_unesco_buffer',
                  'am:am_gga_gew_pub',
                  'am:am_pln_bhpl_pub',
                  'am:am_pln_richtp_pub',
                  'lu:lu_wet_ar_el_pub',
                  'lu:lu_wet_ar_geh_pub',
                  'lu:lu_wet_bk_el_pub',
                  'lu:lu_wet_la_el_pub',
                  'lu:lu_wet_la_geh_pub'
                ],
                FORMAT: 'image/png',
                TRANSPARENT: 'true',
                VERSION: '1.3.0'
              },
              serverType: 'geoserver',
              opacity: 1
            })
          }),
          brussels: null
        },
        locationPlan: {
          flanders: null,
          brussels: null
        }
      }
    }
  },
  computed: {
    legendUrls () {
      const mapTypesWithoutLegend = ['regionPlan', 'waterPolicyAdvice', 'preemption', 'locationPlan']
      if (mapTypesWithoutLegend.includes(this.mapType)) return []
      let overlayLayer = this.overlayLayers[this.mapType][this.region]
      if (Array.isArray(overlayLayer)) {
        overlayLayer = overlayLayer[0] // Use only the first overlay layer
      }
      if (overlayLayer && overlayLayer.getSource() instanceof TileWMS) {
        return this.extractLegendUrls(overlayLayer.getSource())
      }
      return []
    },
    sizeClass () {
      const sizeMap = {
        default: 'tw-max-h-[90%]',
        adjusted: 'tw-h-[60vh]'
      }
      return sizeMap[this.size] || sizeMap.default
    }
  },
  watch: {
    mapType: 'updateMapLayers',
    baseType: 'updateMapLayers'
  },
  methods: {
    extractImageBlob (scaleFactor = 1) {
      return new Promise((resolve, reject) => {
        const map = this.map
        map.renderSync() // Ensure the map is fully rendered
        const canvas = map.getViewport().querySelector('canvas')

        if (!canvas) {
          console.error(`Canvas not found for map ${this.mapType}`)
          reject(new Error(`Canvas not found for map ${this.mapType}`))
          return
        }

        // Create a high-resolution off-screen canvas
        const offscreenCanvas = document.createElement('canvas')
        offscreenCanvas.width = 900
        offscreenCanvas.height = 400
        if (scaleFactor > 1) {
          offscreenCanvas.width = canvas.width * scaleFactor
          offscreenCanvas.height = canvas.height * scaleFactor
        }
        const ctx = offscreenCanvas.getContext('2d')

        // Scale up the drawing
        ctx.drawImage(canvas, 0, 0, offscreenCanvas.width, offscreenCanvas.height)

        // Convert to a data URL or Blob for upload
        offscreenCanvas.toBlob(blob => {
          if (blob) {
            resolve({ blob, mapType: this.mapType })
          } else {
            reject(new Error('Failed to generate blob from canvas'))
          }
        }, 'image/png')
      })
    },
    render () {
      this.initMap()
    },
    initMap () {
      const { longitude, latitude } = this.location
      const coordinates = fromLonLat([longitude, latitude])
      const layers = this.getLayers()
      this.map = new Map({
        target: this.$refs.mapContainer,
        layers: layers,
        view: new View({
          center: coordinates,
          zoom: this.defaultZoom,
          minZoom: this.minZoom,
          maxZoom: this.maxZoom
        }),
        interactions: defaultInteractions({ dragPan: this.dragEnabled })
      })

      this.addMarker(coordinates)

      // Apply zoom lock if enabled
      if (this.keepZoomCentered) {
        this.map.on('moveend', () => {
          this.map.getView().animate({ center: coordinates, duration: 200 }) // Smoothly recenter
        })
      }

      this.trackLayerLoading(layers)
    },
    addMarker (coordinates) {
      const marker = new Feature({
        geometry: new Point(coordinates),
        zIndex: 0
      })
      marker.setStyle(
        new Style({
          fill: new Fill({ color: 'rgba(255,46,67,0.47)' }),
          stroke: new Stroke({ color: '#ffcc33', width: 2 }),
          image: new CircleStyle({
            radius: 10,
            fill: new Fill({ color: '#ff002c' }),
            stroke: new Stroke({ color: '#ffffff', width: 2 })
          })
        })
      )
      this.locationSource.clear()
      this.locationSource.addFeature(marker)
      const locationLayer = new VectorLayer({ source: this.locationSource, zIndex: 1000 })
      this.map.addLayer(locationLayer)
    },
    getLayers () {
      const baseLayer = this.baseType === 'satellite' ? this.layers.satellite : this.layers.osm
      const overlayLayer = this.overlayLayers[this.mapType][this.region] || null
      if (overlayLayer && overlayLayer.constructor === Array) {
        return [baseLayer, ...overlayLayer]
      }
      return overlayLayer ? [baseLayer, overlayLayer] : [baseLayer]
    },
    updateMapLayers () {
      if (this.map) {
        this.map.getLayers().clear()
        this.getLayers().forEach(layer => this.map.addLayer(layer))
        this.addMarker(fromLonLat([this.location.longitude, this.location.latitude]))
      }
    },
    trackLayerLoading (layers) {
      let loadingTiles = 0

      layers.forEach(layer => {
        const source = layer.getSource()
        if (!source || !source.on) return // Ignore layers without a source

        source.on('tileloadstart', () => {
          loadingTiles++
          this.isLoading = true
        })

        source.on('tileloadend', () => {
          loadingTiles--
          if (loadingTiles === 0) this.isLoading = false
        })

        source.on('tileloaderror', () => {
          loadingTiles--
          if (loadingTiles === 0) this.isLoading = false
        })
      })
    },
    extractLegendUrls (source) {
      if (source instanceof TileWMS) {
        const params = source.getParams()
        const layers = Array.isArray(params.LAYERS) ? params.LAYERS : [params.LAYERS]
        return layers.map(layer => ({
          url: `${source.getUrls()[0]}?SERVICE=WMS&REQUEST=GetLegendGraphic&VERSION=1.3.0&FORMAT=image/png&LEGEND_OPTIONS=forceLabels:on&LAYER=${layer}`
        }))
      }
      return []
    }
  }
}
</script>

<style>
</style>
