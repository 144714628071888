<template>
  <div>
    <div class="tw-my-2 tw-flex tw-justify-between tw-items-center">
      <span class="tw-italic tw-text-gray-500">
        Deze kaarten komen in het schattingsverslag. Je kan per kaart inzoomen (scroll) en herpositioneren.
        Sla de kaarten nadien op met de "Opslaan" knop. De kaarten zullen in het schattingsverslag op deze
        wijze worden voorgesteld.
      </span>
      <button type="button" :disabled="isLoading" class="btn btn-sm btn-success" @click="captureImages">
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        Opslaan
      </button>
    </div>
    <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-mt-4">
      <div v-for="(mapType, index) in mapTypes" :key="mapsKey + '-' + index">
        <div class="tw-flex tw-justify-start">
          <h3>{{getTitle(mapType)}}</h3>
          <button
            v-if="!mapTypesWithoutLegend.includes(mapType)"
            type="button"
            class="tw-ml-auto tw-px-2 tw-rounded-full tw-border"
            @click="showLegendModal(mapType)"
          >
            Legende
          </button>
        </div>
        <EntityLayerMap
          :ref="'map-' + mapType"
          :location="geolocation"
          :region="region"
          :baseType="mapType === 'locationPlan' ? 'satellite' : 'osm'"
          :mapType="mapType"
          :showLegend="false"
          :minZoom="getMinZoomForMapType(mapType)"
          :maxZoom="getMaxZoomForMapType(mapType)"
          :defaultZoom="getDefaultZoomForMapType(mapType)"
        />
      </div>
    </div>
    <div class="tw-my-2 tw-flex tw-justify-end">
      <button type="button" :disabled="isLoading" class="btn btn-sm btn-success tw-my-2" @click="captureImages">
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        Opslaan
      </button>
    </div>
    <EntityLayerMapLegendModal
      ref="legendModal"
      :legendUrls="legendUrls"
    />
  </div>

</template>

<script>
import EntityLayerMap from '@/components/properties/EntityLayerMap'
import EntityLayerMapLegendModal from '@/components/properties/EntityLayerMapLegendModal'

import {
  uploadFile as uploadPropertyFile,
  createPropertyFile,
  createPropertyFileFolder,
  getPropertyFileFolders
} from '@/services/properties'

import {
  uploadFile as uploadProjectFile,
  createProjectFile,
  createProjectFileFolder,
  getProjectFileFolders
} from '@/services/projects'

import {
  successModal
} from '@/modalMessages'

export default {
  name: 'EntityLayerMapGrid',
  components: { EntityLayerMapLegendModal, EntityLayerMap },
  props: {
    geolocation: {
      type: Object,
      default: () => {
        return {}
      }
    },
    region: {
      type: String,
      default: 'flanders'
    },
    selectedMapTypes: {
      type: Array,
      default: () => { return [] }
    },
    availableMapTypes: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      isLoading: false,
      mapTypes: [],
      rendered: false,
      mapsKey: 0,
      mapTypesWithoutLegend: ['waterPolicyAdvice', 'preemption', 'locationPlan'],
      legendUrls: []
    }
  },
  watch: {
    geolocation: {
      handler (geolocation) {
        this.mapsKey++ // Increment the key to force re-rendering of the maps
        this.rendered = false
        this.renderAllMaps()
      },
      deep: true // Enable deep watching to detect changes within the contact object
    }
  },
  methods: {
    getTitle (mapType) {
      return this.availableMapTypes[mapType].label
    },
    toggleMapType (mapType) {
      const index = this.selectedMapTypes.indexOf(mapType)
      if (index === -1) {
        this.selectedMapTypes.push(mapType) // Add if not present
        this.$nextTick(() => {
          // render the new map by reference
          this.$refs[`map-${mapType}`][0].render()
        })
      } else {
        this.selectedMapTypes.splice(index, 1) // Remove if already present
      }
    },
    getMinZoomForMapType (mapType) {
      return this.availableMapTypes[mapType].zoomConfig.min
    },
    getMaxZoomForMapType (mapType) {
      return this.availableMapTypes[mapType].zoomConfig.max
    },
    getDefaultZoomForMapType (mapType) {
      return this.availableMapTypes[mapType].zoomConfig.default
    },
    renderAllMaps () {
      Object.keys(this.$refs)
        .filter(key => key.startsWith('map-')) // Get only refs that start with 'map-'
        .forEach(key => {
          if (this.$refs[key] && typeof this.$refs[key][0].render === 'function') {
            this.$refs[key][0].render() // Call the render method of each component
          }
        })
    },
    render () {
      if (this.rendered) return
      this.mapTypes = this.selectedMapTypes
      this.$nextTick(() => {
        // Because the BaseModal uses a v-if to render the content, so we have to wait for the render to finish
        this.renderAllMaps()
      })
      this.rendered = true
    },
    async captureImages () {
      this.isLoading = true
      const maps = []
      this.mapTypes.forEach((mapType) => {
        if (this.$refs[`map-${mapType}`]) {
          maps.push(this.$refs[`map-${mapType}`][0])
        }
      })
      return await this.captureAndUploadMaps(maps)
    },
    async captureAndUploadMaps (maps, scaleFactor = 1) {
      try {
        const folderId = await this.getOrCreateEntityFolder(this.$route.params.id)

        // Use map() to collect promises properly
        const promises = maps.map(async (map) => {
          const { blob, mapType } = await map.extractImageBlob(scaleFactor)
          return this.upload(blob, mapType, folderId)
        })

        // Wait for all uploads to complete
        const responses = await Promise.all(promises)

        this.isLoading = false
        successModal('De kaarten zijn succesvol opgeslagen')

        return responses
      } catch (error) {
        console.error('Error processing maps:', error)
        this.isLoading = false // Ensure loading state is reset on failure
      }
    },
    async uploadFile (entityId, formData) {
      let key = ''
      let filename = ''
      if (this.$route.meta.entity_type === 'property') {
        ({ key, filename } = (await uploadPropertyFile(entityId, formData)).data)
      } else {
        ({ key, filename } = (await uploadProjectFile(entityId, formData)).data)
      }
      return { key, filename }
    },
    async getOrCreateEntityFolder (entityId) {
      const entityType = this.$route.meta.entity_type
      const getFunction = entityType === 'property' ? getPropertyFileFolders : getProjectFileFolders
      const createFunction = entityType === 'property' ? createPropertyFileFolder : createProjectFileFolder
      const response = await getFunction(entityId, { query: 'Kaarten' })
      let folderId = response?.data?.results?.[0]?.id
      if (!folderId) {
        const response = await createFunction(entityId, { name: 'Kaarten' })
        folderId = response.data.id
      }
      return folderId
    },
    async createEntityFile (entityId, payload) {
      if (this.$route.meta.entity_type === 'property') {
        return await createPropertyFile(entityId, payload)
      } else {
        return await createProjectFile(entityId, payload)
      }
    },
    getFileTypeKey (mapType) {
      const mapTypes = {
        regionPlan: 'region_plan_map',
        cadaster: 'cadaster_map',
        waterPolicy: 'water_policy_map',
        waterPolicyAdvice: 'water_policy_advice_map',
        preemption: 'preemption_map',
        heritage: 'heritage_map',
        locationPlan: 'location_plan_map'
      }
      return mapTypes[mapType] || ''
    },
    async upload (blob, mapType, folderId) {
      try {
        const formData = new FormData()
        formData.append('file', blob, `${this.getTitle(mapType)}.png`)
        const { key, filename } = await this.uploadFile(this.$route.params.id, formData)
        const payload = { key, filename, file_type_key: this.getFileTypeKey(mapType), folder_id: folderId }
        return await this.createEntityFile(this.$route.params.id, payload)
      } catch (error) {
        console.error(error)
      }
    },
    showLegendModal (mapType) {
      if (mapType === 'regionPlan') {
        this.legendUrls = [{ url: 'https://iam-media-prod.s3.eu-central-1.amazonaws.com/map_legends/legend_gewestinfo_map.png' }]
      } else {
        this.legendUrls = this.$refs[`map-${mapType}`][0].legendUrls
      }
      this.$refs.legendModal.show()
    }
  }
}
</script>
