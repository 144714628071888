<template>
  <div>
    <h2 class="tw-mt-2 tw-mb-8">
      <router-link
        :to="backRoute"
        title="Ga terug"
        class="!tw-text-tg-color hover:tw-opacity-80"
      >
        <i class="fal fa-arrow-left tw-mr-2" />
      </router-link>
      Kaarten
    </h2>
    <div class="tw-my-2 tw-flex tw-gap-2">
      <button type="button" :class="['btn btn-sm btn-primary', {'btn-outline': mode !== 'geo'}]" @click="toggleMode('geo')">
        <i class="glyphicon glyphicon-globe"></i> Geografisch
      </button>
      <button type="button" :disabled="!geolocation.location" :class="['btn btn-sm btn-primary', {'btn-outline': mode !== 'image'}]" @click="toggleMode('image')">
        <i class="glyphicon glyphicon-save"></i> Overzicht kaarten
      </button>
    </div>
    <div v-show="mode === 'geo'">
      <div class="tw-my-2">
        <button type="button" class="btn btn-sm btn-success" @click="showBuildingUnits">
          <i class="fa fa-arrow-right"/>
          Volgende
        </button>
      </div>
      <EntityMap
        ref="geoMap"
        :latitude="geolocation.latitude"
        :longitude="geolocation.longitude"
        :parcelString="parcelString"
      />
      <BuildingUnitsModal
        ref="buildingUnitsModal"
        :entity="entity"
        :geolocation="geolocation"
        @hide="getGeoLocation"
      />
    </div>
    <div v-show="mode === 'image'">
      <EntityLayerMapGrid
        ref="imageMaps"
        :geolocation="geolocation"
        :region="region"
        :selectedMapTypes="['regionPlan', 'cadaster', 'waterPolicy', 'waterPolicyAdvice', 'preemption', 'heritage', 'locationPlan']"
        :availableMapTypes="availableMapTypes"
      />
    </div>
  </div>
</template>
<script>
import BuildingUnitsModal from '@/components/properties/BuildingUnitsModal'
import EntityMap from '@/components/properties/EntityMap'
import EntityLayerMapGrid from '@/components/properties/EntityLayerMapGrid'
import { getProperty, getPropertyGeolocation } from '@/services/properties'
import { getProjectById, getProjectGeolocation } from '@/services/projects'

export default {
  name: 'EntityMaps',
  components: { BuildingUnitsModal, EntityMap, EntityLayerMapGrid },
  constants: {
    mapTypes: {
      default: 'Standaard',
      regionPlan: 'Gewestplan',
      cadaster: 'Kadaster',
      waterPolicy: 'Watertoets',
      waterPolicyAdvice: 'Watertoets advieskaart',
      preemption: 'Voorkooprecht',
      heritage: 'Onroerend erfgoed',
      locationPlan: 'Inplantingsplan'
    }
  },
  data () {
    return {
      entity: null,
      geolocation: {},
      parcelString: '',
      mode: 'geo',
      selectedMapType: 'default'
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    backRoute () {
      return this.$route.meta.backRoute
    },
    region () {
      if (this.entity && this.entity.region) {
        return this.entity.region === 1 ? 'brussels' : 'flanders'
      }
      return 'flanders'
    },
    availableMapTypes () {
      if (this.region === 'flanders') {
        return {
          regionPlan: {
            label: 'Gewestplan',
            zoomConfig: {
              min: 10,
              max: 20,
              default: 16
            }
          },
          cadaster: {
            label: 'Kadaster',
            zoomConfig: {
              min: 10,
              max: 20,
              default: 18
            }
          },
          waterPolicy: {
            label: 'Watertoets',
            zoomConfig: {
              min: 10,
              max: 20,
              default: 16
            }
          },
          waterPolicyAdvice: {
            label: 'Watertoets advieskaart',
            zoomConfig: {
              min: 10,
              max: 20,
              default: 16
            }
          },
          preemption: {
            label: 'Voorkooprecht',
            zoomConfig: {
              min: 10,
              max: 20,
              default: 16
            }
          },
          heritage: {
            label: 'Onroerend erfgoed',
            zoomConfig: {
              min: 10,
              max: 20,
              default: 18
            }
          },
          locationPlan: {
            label: 'Inplantingsplan',
            zoomConfig: {
              min: 10,
              max: 19,
              default: 18
            }
          }
        }
      }
      return {}
    }
  },
  methods: {
    init () {
      const promises = [this.getEntity(), this.getGeoLocation()]
      Promise.all(promises).then(() => {
        this.$nextTick(() => {
          this.$refs.geoMap.render()
        })
      })
    },
    goBack () {
      this.$router.push(this.backRoute)
    },
    async getGeoLocation () {
      const entityId = this.$route.params.id
      let response = null
      if (this.$route.meta.entity_type === 'property') {
        response = await getPropertyGeolocation(entityId)
      } else {
        response = await getProjectGeolocation(entityId)
      }
      this.geolocation = response.data
      return response
    },
    async getEntity () {
      const entityId = this.$route.params.id
      let response = null
      if (this.$route.meta.entity_type === 'property') {
        response = await getProperty(entityId)
      } else {
        response = await getProjectById(entityId)
      }
      this.entity = response.data
      this.parcelString = this.entity.parcels
      return response
    },
    toggleMode (mode) {
      this.mode = mode
      if (this.mode === 'image') {
        this.$nextTick(() => {
          this.$refs.imageMaps.render()
        })
      }
    },
    async showBuildingUnits () {
      // Important for a parcel to be selected before fetching the building units
      const parcels = this.$refs.geoMap.getParcels()
      const coordinates = this.$refs.geoMap.getCoordinates()
      this.$refs.buildingUnitsModal.show(parcels, coordinates)
    }
  }
}
</script>
