<template>
  <BaseModal
    ref="modal"
    title="Legende"
    max-width="tw-max-w-xl"
    @hide="$emit('close')"
  >
    <div v-for="(legend, index) in legendUrls" :key="index" class="tw-mt-1 tw-flex tw-items-center">
      <img :src="legend.url" alt="Legend" class="tw-max-w-full tw-h-auto tw-mr-2">
    </div>
  </BaseModal>
</template>

<script>

export default {
  name: 'EntityLayerMapLegendModal',

  props: {
    legendUrls: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    }
  }
}
</script>
